const thayer = [
  "thayer",
  "tigers",
  "streak",
  "pranks",
  "senior",
  "sawyer",
  "junior",
  "glover",
  "cahall",
  "coding",
  "french",
  "tracks",
  "humans",
  "tables",
  "school",
  "hockey",
  "campus",
  "nurses",
  "office",
  "soccer",
  "parent",
  "poetry",
  "acting",
  "dramas",
  "google",
  "wordle",
  "orange",
  "awards",
  "murphy",
  "golfer",
  "tennis",
  "relays",
  "sprint",
  "guitar",
  "chorus",
  "lesson",
  "guests",
  "player",
  "course",
  "tutors",
  "fellow",
  "benign",
  "turkey",
  "monday",
  "cohort",
  "action",
  "status",
  "flasks",
  "object",
  "breaks",
  "online",
  "author",
  "answer",
  "gately",
  "laptop",
  "choice",
  "create",
  "isokan",
  "robots",
  "loving",
  "friend",
  "family",
  "growth",
  "bright",
  "future",
  "donald",
  "keough",
  "delete",
  "listen",
  "review",
  "prizes",
  "harper",
  "praise",
  "random",
  "friday",
  "tiktok",
  "sunday",
  "hacker",
  "stormy",
  "report",
  "driver",
  "banana",
  "salmon",
  "panini",
  "genres",
  "source",
  "custom",
  "solver",
  "united",
  "speech",
  "france",
  "career",
  "singer",
  "newsie",
  "embark",
  "spirit",
  "talent",
  "common",
  "equity",
  "combos",
  "chiari",
]

export default thayer;
